import React from 'react'
import moment from "moment"
import "./Blog.css"
import { motion } from "framer-motion"

const blogVariants = {
  hidden: {
    scale: 0
  },
  visible: {
    rotate: 360,
    scale: 1,
    transition: {
      delay: 0.7,
      type: "spring",
      ease: "easeOut",
      stiffness: 160,
      damping: 20
    }
  },
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.4,
      yoyo: Infinity
    }
  }
}

function Blog(props) {
  return (
    <motion.div className="Blog text-dark col mb-4"
      variants={blogVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
    >
      <div className="card">
        <img src={props.blog.imageUrl} className="card-img-top" alt="blogImage" />
        <div className="card-body">
          <h5 className="card-title" style={{fontSize:23}}>{props.blog.title}</h5>
          <p className="card-text" style={{fontSize:18}}>{props.blog.content.slice(0, 100)}...</p>
        </div>
        <div className="card-footer" style={{ display: "flex", justifyContent: "space-between" }}>
          <small className="text-muted" style={{ letterSpacing: 2 }}>{moment(props.blog.postedAt.toDate()).calendar()}</small>
          <span className="badge badge-pill badge-danger">Read More</span>
        </div>
      </div>
    </motion.div>
  )
}

export default Blog
