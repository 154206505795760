import React, { Component } from 'react'
import { connect } from "react-redux"
import { signIn } from '../store/actions/authAction';
import { motion } from "framer-motion"

class SignIn extends Component {
  state = {
    email: "",
    password: ""
  }

  submit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }

  change = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  render() {
    const containerVariants = {
      hidden: {
        opacity: 0
      },
      visible: {
        opacity: 1,
        transition: {
          delay: 0.2,
          duration: 1
        }
      },
      exit: {
        x: "-100vw",
        transition: { ease: "easeInOut" }
      }
    }
    return (
      <motion.div className="container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <form onSubmit={this.submit}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input onChange={this.change} type="email" className="form-control" id="email" aria-describedby="emailHelp" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input onChange={this.change} type="password" className="form-control" id="password" />
          </div>
          <button className="btn btn-primary">Login</button>
          <div className="text-danger">{this.props.authError ? <p>{this.props.authError}</p> : null}</div>
        </form>
      </motion.div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.firebase.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
