export const newBlog = (blog) => {
    return (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();
        firestore.collection("blogs").add({
            ...blog,
            postedAt: new Date(),
            applauseCount: 0,
        }).then(() => {
            dispatch({ type: "NEW_BLOG", blog: blog })
        }).catch(err => dispatch({ type: "ERR_BLOG", err }))
    }
}