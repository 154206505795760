import React, { Component } from 'react'
import { connect } from "react-redux"
import { newBlog } from '../store/actions/blogAction';
import { storage } from "../config/fbConfig"
import SignIn from './SignIn';
import { signOut } from '../store/actions/authAction';
import { motion } from "framer-motion"

let file;

class Admin extends Component {
  state = {
    title: "",
    content: "",
    imageUrl: "/images/placeholder.svg",
    fileName: "RGTechCafe"
  }

  submit = (e) => {
    e.preventDefault();
    this.props.newBlog(this.state)
  }

  uploadImage = () => {
    let url;
    const storageRef = storage.ref(this.state.fileName)
    storageRef.put(file).then(async () => {
      url = await storageRef.getDownloadURL();
      this.setState({
        imageUrl: url
      })
    }).catch(err => console.log(err))
  }

  change = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  imageChange = (e) => {
    file = e.target.files[0]
    this.setState({
      [e.target.id]: e.target.files[0]["name"]
    })
  }


  render() {
    const containerVariants = {
      hidden: {
        opacity: 0
      },
      visible: {
        opacity: 1,
        transition: {
          delay: 0.2,
          duration: 1
        }
      },
      exit: {
        x: "-100vw",
        transition: { ease: "easeInOut" }
      }
    }
    if (this.props.auth.uid) {
      return (
        <motion.div className="container"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <form onSubmit={this.submit}>
            <div className="input-group mt-3 mb-3">
              <div className="custom-file">
                <label className="custom-file-label" htmlFor="fileName">Choose file</label>
                <input type="file" class="custom-file-input" id="fileName" onChange={this.imageChange} />
              </div>
              <div className="input-group-append">
                <span onClick={this.uploadImage} className="input-group-text" id="upload">Upload</span>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input type="text" className="form-control" id="title" placeholder="Title" onChange={this.change} />
            </div>
            <div class="form-group">
              <label htmlFor="content">Content</label>
              <textarea className="form-control" id="content" rows="3" onChange={this.change}></textarea>
            </div>
            <button className="btn btn-pill btn-success">Post</button>
          </form>
          <button className="btn mt-3 btn-pill btn-danger" onClick={this.props.signOut}>Logout</button>
        </motion.div>
      )
    }
    else {
      return (
        <SignIn />
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    newBlog: (blog) => dispatch(newBlog(blog)),
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
