import React, { Component } from 'react'
import { connect } from "react-redux"
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from "redux"
import moment from "moment"
import "./BlogDetail.css"
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';
import firebase from "../../../config/fbConfig"

class BlogDetail extends Component {

  changeCount = () => {
    const db = firebase.firestore();
    db.collection("blogs").doc(this.props.match.params.id).update({ applauseCount: this.props.blog.applauseCount + 1 });
  }

  render() {
    const containerVariants = {
      hidden: {
        opacity: 0
      },
      visible: {
        opacity: 1,
        transition: {
          duration: 0.7
        }
      },
      exit: {
        x: "-100vw",
        transition: { ease: "easeInOut" }
      }
    }
    const { blog } = this.props;

    if (blog) {
      return (
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className="detailBanner">
            <img className="detailImg" src={blog.imageUrl} alt="resp_img" />
            <div onClick={this.changeCount} className="applause rounded-circle" data-toggle="tooltip" data-placement="top" title="Give An Applause">
              <span className="badge badgeCount badge-pill badge-primary">{blog.applauseCount}</span>
              <i className="fas fa-sign-language fa-2x"></i>
            </div>
          </div>
          <div className="container mt-3">
            <div className="postDetail">
              <p style={{ fontWeight: "bold", letterSpacing: 2 }}>
                <i className="fas fa-calendar-alt white"></i>
                {moment(blog.postedAt.toDate()).calendar()}
              </p>
              <Link className="text-dark" to="/">
                <p style={{ fontWeight: "bold", letterSpacing: 2 }}>
                  By: Rahul Gandhi
              </p>
              </Link>
            </div>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <h1 className="display-5 text-center" style={{fontFamily: "Gafata"}}>{blog.title}</h1>
                <hr />
                <p className="lead text-justify" style={{ fontSize: 20,fontFamily: 'Montserrat',}}>{blog.content}</p>
              </div>
            </div>
          </div>
        </motion.div>
      )
    }
    else {
      return (
        <motion.div className="lds-grid"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </motion.div>
      )
    }
  }
}

const mapStateToProps = (state, props) => {
  const id = props.match.params.id;
  const blogs = state.firestore.data.blogs;
  const blog = blogs ? blogs[id] : null;
  return {
    blog: blog
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: "blogs" }
  ])
)(BlogDetail)
