import React, { Component } from 'react'
import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import { Link } from 'react-router-dom'
import { compose } from "redux"
import "../Home.css"
import Blog from './Blog'

class BlogCardHome extends Component {

  render() {
    const { blogs } = this.props;
    return (
      <div className="container blogs">
        <div className="card-columns">
          {blogs && blogs.map((blog => {
            return (
              <Link to={"/blog/" + blog.id} key={blog.id}>
                <Blog blog={blog} />
              </Link>
            )
          }))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    blogs: state.firestore.ordered.blogs,
    // auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'blogs', orderBy: ["postedAt", "desc"] }
  ])
)(BlogCardHome)

