import React from 'react'
import { motion } from "framer-motion"
import "./Home.css"

const bannerVariants = {
  hidden: {
    y: -250
  },
  visible: {
    y: 0,
    transition: {
      delay: 0.2,
      duration: 0.5,
      ease: "easeInOut"
    }
  }
}

const imageVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 0.9,
    transition: {
      delay: 0.5,
      duration: 1,
    }
  }
}

function BannerHome() {
  return (
    <div>
      <motion.div
        className="imgBanner"
        variants={bannerVariants}
        initial="hidden"
        animate="visible"
      >
        <h3>Rahul Gandhi</h3>
      </motion.div>
      <div>
        <motion.img
          variants={imageVariants}
          src="https://firebasestorage.googleapis.com/v0/b/blog-rg.appspot.com/o/rahul.jpg?alt=media&token=9bda8754-d728-4e4f-9def-0b1911725d7b" className="imgPro rounded-circle" alt="" />
      </div>
    </div>
  )
}

export default BannerHome
