import React from 'react'
import BannerHome from "./banner_Home"
import BlogCardHome from './Blogs/blogCard_Home'
import { motion } from "framer-motion"

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 1
    }
  },
  exit: {
    x: "-100vw",
    transition: { ease: "easeInOut" }
  }
}


function Home() {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <BannerHome />
      <BlogCardHome />
    </motion.div>
  )
}

export default Home
