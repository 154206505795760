import React, { useState } from "react"
import { Route, Switch, useLocation } from "react-router-dom"
import Admin from "./components/Admin";
import BlogDetail from "./components/Home/Blogs/BlogDetail";
import Home from "./components/Home/Home";
import HomeAnim from "./components/HomeAnim/HomeAnim";
import { AnimatePresence } from "framer-motion"

function App() {
  const location = useLocation();
  const [homeAnim, setHomeAnim] = useState(true);
  setTimeout(() => {
    setHomeAnim(false)
  }, 8000);
  return (
    <div className="App">
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route exact path="/" component={homeAnim ? HomeAnim : Home}></Route>
          <Route path="/admin" component={Admin}></Route>
          <Route path="/blog/:id" component={BlogDetail}></Route>
          {/* <Route path="/signIn" component={SignIn}></Route> */}
        </Switch>
      </AnimatePresence>
    </div>
  );
}

export default App;
