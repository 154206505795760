import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"

var firebaseConfig = {
    apiKey: "AIzaSyAtdJekbGRpjS097AG_Th7-zTvrBaiRlE8",
    authDomain: "blog-rg.firebaseapp.com",
    databaseURL: "https://blog-rg.firebaseio.com",
    projectId: "blog-rg",
    storageBucket: "blog-rg.appspot.com",
    messagingSenderId: "286740155725",
    appId: "1:286740155725:web:78b47dcadf98783bb47b04",
    measurementId: "G-XNZWY13XYD"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
export const storage = firebase.storage()
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase